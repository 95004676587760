<template>
  <data-view-layout>
    <div slot="left-pane" class="left-pane">
      <!-- 左1：年月日产量 -->
      <data-view-card
        style="width:100%;height:20.93%;"
        v-loading="left1.loading">
        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :data="left1.tableData"
              :titles="left1.tableTitles"
            ></scroll-table>
          </template>
        </resize-wrap>
      </data-view-card>

      <!-- 左2：产能效率 -->
      <data-view-card
        class="flex justify-around align-center mt-11 p-15"
        style="width:100%;height:20.93%;"
        v-loading="left2AndMid3.loading">
        <chart-pie-warn
          style="height:100%;width:30%;"
          v-bind="item"
          v-for="item in left2AndMid3.productivityEfficiencyDtoList"
          :key="item.name"
        />
      </data-view-card>

      <!-- 左3 -->
      <data-view-card
        class="mt-10"
        style="width:100%;height:58.13%;"
        v-loading="left3.loading"
      >
        <chart-bar
          style="width:100%;height:33.33%;"
          v-for="item in left3.dataCompareDtoList"
          :key="item.type"
          :title="item.type"
          :unit="item.unit"
          :data="item"
        />
      </data-view-card>
    </div>

    <div slot="middle-pane" class="middle-pane">
      <!-- 地图 type：0总览，1商砼，2砂浆，3水泥，0车辆 -->
      <data-view-map
        :type="0"
        style="width:100%;height:52.81%;"
        :totals="midTotals"
        @area-change="areaChange"
      />

      <!-- 中2: 区年月日产量 -->
      <data-view-card
        style="width:100%;height:23.12%;position:relative;"
        v-loading="mid2.loading">
        <div class="table-cate-bar">
          <div
            class="cate-item"
            @click="selectedCate(key)"
            v-for="({name, key}) in mid2.tabs"
            :key="key"
            :class="{active: mid2ActKey == key}"
          >{{name}}</div>
        </div>

        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :key="mid2ActKey"
              :data="mid2[mid2ActKey].data"
              :titles="mid2[mid2ActKey].title"
            ></scroll-table>
          </template>
        </resize-wrap>
      </data-view-card>

      <!-- 中3: 完成率 -->
      <data-view-card
        class="mt-11 flex"
        style="width:100%;height:22.73%"
        v-loading="left2AndMid3.loading">
        <chart-dash-board
          style="width:33.33%;height:100%;"
          v-bind="item"
          v-for="item in left2AndMid3.completionRateDtoList"
          :key="item.name"
        />
      </data-view-card>
    </div>

    <div slot="right-pane" class="right-pane">
      <data-view-card
        class="flex justify-around mb-11"
        style="width:100%;height:20.93%;position:relative;"
        v-loading="right1And2.loading">
        <div class="total-titles">
          <div class="total-icon"></div>
          <div class="title-item">车辆数</div>
          <div class="title-item">上线数</div>
        </div>
        <div class="total-item" v-for="item in right1And2.carAndOnlines" :key="item.type">
          <img v-if="right1And2.carAndOnlinesIcon[item.type]" class="total-icon" :src="right1And2.carAndOnlinesIcon[item.type]">
          <span v-else>其他</span>
          <div class="total-car ml-10">
            <count-to :startVal="0" :endVal="item.vehiCount" :duration="2000"/>
          </div>
          <div class="total-online ml-10">
            <count-to :startVal="0" :endVal="item.vehiOnlineCount" :duration="2000"/>
          </div>
        </div>
      </data-view-card>

      <data-view-card
        class="flex mb-10 justify-around"
        style="width:100%;height:20.93%;"
        v-loading="right1And2.loading">
        <chart-pie2
          style="width:33.33%;height:100%;"
          v-for="(item, index) in right1And2.pies"
          :key="item.name"
          :data="item"
          :color="right1And2.colors[index]"
        />
      </data-view-card>

      <data-view-card
        style="width:100%;height:58.13%;"
        v-loading="right3.loading">
        <chart-line
          style="width:100%;height:33.33%;"
          v-for="(item,index) in right3.linesData"
          :key="item.title"
          :title="item.title"
          :label="right3.labels[index]"
          :unit="item.unit"
          :data="item"
        />
      </data-view-card>
    </div>
  </data-view-layout>
</template>

<script>
import { ChartLine, ChartBar, ChartPie2, ChartPieWarn, ChartDashBoard } from '@/components/Charts'
import { DataViewLayout, DataViewCard } from '@/components/DataView'
import {
  getProductionValueByUple,
  getProductionRateByUple,
  getMonthCompareProductionRateByUple,
  getTotalDayProductionValueByUple,
  getUpleProductionRateRankData,
  getTotalCompVehiDataIndex,
  getTotalCompVehiCount,
  getTotalVehiOverSpeedAndWarnAndProjCount
} from '@/apis/dataView'
import DataViewMap from './components/DataViewMap'
import CountTo from 'vue-count-to'
import ScrollTable from '@/components/ScrollTable'
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      // 左1表格
      left1: {
        loading: false,
        // 左上表格：日、月、年产量
        tableTitles: [
          { name: '产品名称', prop: 'productionName' },
          { name: '日产量(方)', prop: 'dayWeight' },
          { name: '月产量(方)', prop: 'monthWeight' },
          { name: '年产量(方)', prop: 'yearWeight' }
        ],
        tableData: []
      },
      left2AndMid3: {
        loading: false,
        productivityEfficiencyDtoList: [
          { name: '混凝土产能效率', value: 0 },
          { name: '砂浆产能效率', value: 0 },
          { name: '水泥产能效率', value: 0 }
        ],
        completionRateDtoList: [
          { name: '混凝土完成率', value: 0 },
          { name: '砂浆完成率', value: 0 },
          { name: '水泥完成率', value: 0 }
        ]
      },
      left3: {
        loading: false,
        dataCompareDtoList: [
          { type: '混凝土月产量对比', xAxis: [], yAxis: [], unit: '方' },
          { type: '砂浆月产量对比', xAxis: [], yAxis: [], unit: '方' },
          { type: '水泥月产量对比', xAxis: [], yAxis: [], unit: '方' }
        ]
      },
      mid2ActKey: 'dayWeight',
      mid2: {
        loading: false,
        tabs: [
          { key: 'dayWeight', name: '日产量' },
          { key: 'monthWeight', name: '月产量' },
          { key: 'yearWeight', name: '年产量' },
          { key: 'efficiency', name: '产能效率' }
        ],
        dayWeight: {
          title: [
            { name: '区域排名', prop: 'rank' },
            { name: '商砼', prop: 'concreteArea' },
            { name: '日产量(方)', prop: 'concreteAreaWeight' },
            { name: '砂浆', prop: 'mortarArea' },
            { name: '日产量(方)', prop: 'mortarAreaWeight' },
            { name: '水泥', prop: 'cementArea' },
            { name: '日产量(方)', prop: 'cementAreaWeight' }
          ],
          data: []
        },
        monthWeight: {
          title: [
            { name: '区域排名', prop: 'rank' },
            { name: '商砼', prop: 'concreteArea' },
            { name: '月产量(方)', prop: 'concreteAreaWeight' },
            { name: '砂浆', prop: 'mortarArea' },
            { name: '月产量(方)', prop: 'mortarAreaWeight' },
            { name: '水泥', prop: 'cementArea' },
            { name: '月产量(方)', prop: 'cementAreaWeight' }
          ],
          data: []
        },
        yearWeight: {
          title: [
            { name: '区域排名', prop: 'rank' },
            { name: '商砼', prop: 'concreteArea' },
            { name: '年产量(方)', prop: 'concreteAreaWeight' },
            { name: '砂浆', prop: 'mortarArea' },
            { name: '年产量(方)', prop: 'mortarAreaWeight' },
            { name: '水泥', prop: 'cementArea' },
            { name: '年产量(方)', prop: 'cementAreaWeight' }
          ],
          data: []
        },
        efficiency: {
          title: [
            { name: '区域排名', prop: 'rank' },
            { name: '商砼', prop: 'concreteArea' },
            { name: '产能效率(%)', prop: 'concreteEfficiencyValue' },
            { name: '砂浆', prop: 'mortarArea' },
            { name: '产能效率(%)', prop: 'mortarEfficiencyValue' },
            { name: '水泥', prop: 'cementArea' },
            { name: '产能效率(%)', prop: 'cementEfficiencyValue' }
          ],
          data: []
        }
      },
      midTotals: [
        { name: '企业总数', value: 0, prop: 'compCount' },
        { name: '生产线总数', value: 0, prop: 'queuCount' },
        { name: '车辆总数', value: 0, prop: 'vehiCount' },
        { name: '车辆上线总数', value: 0, prop: 'vehiOnlineCount' }
      ],
      right1And2: {
        loading: false,
        carAndOnlines: [
          { type: 1, vehiCount: 0, vehiOnlineCount: 0 },
          { type: 2, vehiCount: 0, vehiOnlineCount: 0 },
          { type: 3, vehiCount: 0, vehiOnlineCount: 0 }
        ],
        carAndOnlinesIcon: { // 1 商砼 2 砂浆 3 水泥
          1: require('@/assets/imgs/common/shangtong-icon.png'),
          2: require('@/assets/imgs/common/shajiang-icon.png'),
          3: require('@/assets/imgs/common/shuini-icon.png')
        },
        pies: [
          [
            { name: '重点项目', value: 0 },
            { name: '其他项目', value: 100 }
          ],
          [
            { name: '车辆上线率', value: 0 },
            { name: '车辆离线率', value: 100 }
          ],
          [
            { name: '自有车辆', value: 0 },
            { name: '外包车辆', value: 100 }
          ]

        ],
        colors: ['#DA0051', '#FFB508', '#01BD8D']
      },
      right3: {
        loading: false,
        labels: ['上线率', '超速次数', '报警次数'],
        linesData: [
          { title: '近5年重点项目数', unit: '个', xAxis: [], yAxis: [] },
          { title: '车辆超速次数', unit: '次', xAxis: [], yAxis: [] },
          { title: '车辆报警次数', unit: '次', xAxis: [], yAxis: [] }
        ]
      },
      // 产能效率
      efficiencyRankList: []
    }
  },
  methods: {
    areaChange (params) {
      // 左1
      this.getProductionValueByUple(params)
      // 左2 + 中3
      this.getProductionRateByUple(params)
      // 左3
      this.getMonthCompareProductionRateByUple(params)
      // 中2
      this.getTotalDayProductionValueByUple(params)
      this.getUpleProductionRateRankData(params)
      // 表格上方统计
      this.getTotalCompVehiDataIndex(params)
      // 右1 + 右2
      this.getTotalCompVehiCount(params)
      // 右3
      this.getTotalVehiOverSpeedAndWarnAndProjCount(params)
    },

    // 获取左1数据
    async getProductionValueByUple (params) {
      const left1 = this.left1
      left1.loading = true
      const res = await getProductionValueByUple(params)
      if (res.code == 200) {
        left1.loading = false
        left1.tableData = res.data.indexReptStatisticsDtoList
      } else {
        this.$message.error(res.msg)
      }
    },

    // 获取 左2 + 中3 数据
    async getProductionRateByUple (params) {
      const left2AndMid3 = this.left2AndMid3
      left2AndMid3.loading = true
      const res = await getProductionRateByUple(params)
      if (res.code == 200) {
        left2AndMid3.loading = false
        const { productivityEfficiencyDtoList, completionRateDtoList } = res.data
        left2AndMid3.productivityEfficiencyDtoList = productivityEfficiencyDtoList.map(item => {
          return { name: item.productivityTitle, value: item.productivityValue }
        })
        left2AndMid3.completionRateDtoList = completionRateDtoList.map(item => {
          return { name: item.completionRateTitle, value: XEUtils.round(item.completionRateValue * 100, 2) }
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 获取 左3
    async getMonthCompareProductionRateByUple (params) {
      const left3 = this.left3
      left3.loading = true
      const res = await getMonthCompareProductionRateByUple(params)
      if (res.code == 200) {
        left3.loading = false
        // 左下柱状图
        left3.dataCompareDtoList = res.data.dataCompareDtoList.map(item => {
          return {
            type: item.type,
            xAxis: item.lineList,
            yAxis: item.columnList,
            unit: '方'
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 中2 发生改变
    selectedCate (id) {
      if (this.mid2ActKey != id) {
        this.mid2ActKey = id
      }
    },

    // 中2
    async getMid2 (key, params) {
      const dateTypes = { dayWeight: 1, monthWeight: 2, yearWeight: 3 }
      const mid2 = this.mid2
      mid2.loading = true
      const res = await getTotalDayProductionValueByUple({ ...params, dateType: dateTypes[key] })
      mid2.loading = false
      if (res.code == 200) {
        mid2[key].data = (res.data.dayWeightRankList.map((item, index) => {
          item.rank = index + 1
          return item
        })) || []
      } else {
        this.$message.error(res.msg)
      }
    },

    // 中2
    getTotalDayProductionValueByUple (params) {
      this.getMid2('dayWeight', params)
      this.getMid2('monthWeight', params)
      this.getMid2('yearWeight', params)
    },

    // 获取产能效率
    async getUpleProductionRateRankData (params) {
      getUpleProductionRateRankData(params).then((res) => {
        if (res.code == 200) {
          const { efficiencyRankList } = res.data
          this.mid2.efficiency.data = (efficiencyRankList.map((item, index) => {
            item.rank = index + 1
            return item
          })) || []
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 表格上方统计
    async getTotalCompVehiDataIndex (params) {
      const res = await getTotalCompVehiDataIndex(params)
      if (res.code == 200) {
        this.midTotals = this.midTotals.map(item => {
          const value = res.data[item.prop]
          item.value = value
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 右1 + 右2
    async getTotalCompVehiCount (params) {
      const { right1And2 } = this
      right1And2.loading = true
      const res = await getTotalCompVehiCount(params)
      right1And2.loading = false
      if (res.code == 200) {
        right1And2.carAndOnlines = res.data.productVehiCountDtoList || []
        right1And2.pies = res.data.shareRateDtoList.map(item => {
          return [
            { name: item.shareDesc, value: item.sharePercent },
            { name: item.deShareDesc, value: 100 - item.sharePercent }
          ]
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    // 右3
    async getTotalVehiOverSpeedAndWarnAndProjCount (params) {
      const { right3 } = this
      right3.loading = true
      const res = await getTotalVehiOverSpeedAndWarnAndProjCount(params)
      right3.loading = false
      if (res.code == 200) {
        const linesData = res.data.dataCompareDtoList.map((item) => {
          const titles = { 5: '近五年重点项目数', 6: '车辆超速次数', 7: '车辆报警次数', 8: '车辆上线率' }
          return {
            xAxis: item.lineList,
            yAxis: item.columnList,
            title: titles[item.type],
            unit: '次'
          }
        })
        right3.linesData = [{
          xAxis: res.data.projCompareDto.lineList,
          yAxis: [{ value: res.data.projCompareDto.valueList }],
          title: '近5年重点项目数',
          unit: '个'
        }].concat(linesData)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  components: {
    DataViewLayout,
    DataViewCard,
    ChartLine,
    ChartBar,
    ChartPie2,
    ChartPieWarn,
    DataViewMap,
    ChartDashBoard,
    CountTo,
    ScrollTable
  }
}
</script>

<style lang="scss" scoped>
.middle-pane {
  width: calc(41.24% - 22px);
  margin: 0 11px;
  height: 100%;
  position: relative;
}
.left-pane, .right-pane {
  box-sizing: border-box;
  color: #fff;
  width: 29.38%;
  height: 100%;
  padding-bottom: 21px;
}
.table-cate-bar {
  position: absolute;
  top: -22px;
  display: flex;
  .cate-item {
    width: 60px;
    line-height: 22px;
    background: #134F54;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;

    &.active {
      background: #059F55;
    }
  }
}
.total-item, .total-titles {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 12px;
  line-height: 15px;
  height: 100%;
  .total-icon {
    height: 43px;
    vertical-align: middle;
  }
  .total-car, .total-online {
    color: #26FFF8;
    font-size: 20px;
    line-height: 15px;
  }
  .total-online {
    color: #0BFF9D;
  }
}
::v-deep {
  .el-loading-mask {
    background: rgba(0, 0, 0, .45);
  }
  .el-loading-spinner .path{
    stroke: #26FFF8!important;
  }
}
</style>
